<template>
  <div>
    <div class="mapping-page-wrapper">
      <MappingTable
        :mapping-type="mappingType"
        @open-popup="editMapping"
        :top-header-position="topHeaderPosition"
      />
      <SuggestedMappingPopup
        v-if="editingMapping"
        :mapping-type="mappingType"
        :edit-data="editData"
        @close-popup="closePopup"
      />
    </div>
    <div
      class="no-mapping-events"
      v-if="!events?.length"
    >
      No data were found for selected parameters
    </div>
  </div>
</template>

<script>
import { watch, computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { parseMappingTypeFromRouteName } from '@/services/helpers/mappings-mapper';
import MappingTable from '@/components/mapping/common/MappingTable';
import SuggestedMappingPopup from '@/components/mapping/common/SuggestedMappingPopup';

export default {
  components: {
    MappingTable,
    SuggestedMappingPopup,
  },
  props: {
    topHeaderPosition: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const mappingType = computed(() => parseMappingTypeFromRouteName(route.name));
    const events = computed(() => store.getters.mappingEntries);
    const mappingLoading = computed(() => store.getters.mappingLoading);
    const editingMapping = ref(false);
    const editData = ref({});

    const fetchMappingData = () => {
      store.dispatch('loadMappingData', mappingType.value);
    };
    const editMapping = ({ row, feed }) => {
      editData.value = { row, feed };
      editingMapping.value = true;
    };
    const closePopup = (message) => {
      editData.value = {};
      editingMapping.value = false;
      if (message && message === 'reload') {
        fetchMappingData();
      }
    };

    watch(
      () => [
        store.getters.currentPage,
        store.getters.rowsPerPage,
      ],
      fetchMappingData,
    );

    return {
      mappingType,
      events,
      mappingLoading,
      editingMapping,
      editData,
      editMapping,
      closePopup,
    };
  },
};
</script>

<style lang="scss">
.mapping-page-wrapper {
  width: 100%;
}

.no-mapping-events {
  height: 260px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #A9A9A9;
}
</style>
